

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Invoice } from '@/models';
import VueI18n from 'vue-i18n';
import InvoiceCreatorConfig from './config';
import { format } from 'date-fns';
import { DATE_FORMAT_ISO, DATE_FORMAT_NEN } from '@/lib/constants';
import { Language } from '@/plugins/i18n';

@Component({})
export default class InvoiceCreatorDetails extends Vue {
  @Prop({ required: true })
  invoice!: Invoice;
  @Prop({ required: true })
  i18n!: VueI18n;
  @Prop({ required: true })
  config!: InvoiceCreatorConfig;

  formatDate(date: Date): string {
    switch (this.invoice.meta.language) {
      case Language.NL:
        return format(date, DATE_FORMAT_NEN);
      default:
        return format(date, DATE_FORMAT_ISO);
    }
  }

  translationSuffix(): string {
    return this.invoice.isQuotation ? 'quotation' : 'invoice';
  }
}
