import { InvoiceStatus } from '@/models/invoice';

export interface InvoiceCreatorConfig {
  showSave?: boolean;
  showFinish?: boolean;

  disableVatChecker?: boolean;
  disableIncludeVat?: boolean;
  disableLineDelete?: boolean;
  allowedTypes?: InvoiceStatus[];

  ghost?: {
    customer?: {
      showCreateNotification?: boolean;
      name: string;
      email: string;
      info?: { [key: string]: any };
    };
  };
}

export const DEFAULT_CONFIG: InvoiceCreatorConfig = {
  showSave: true,
  showFinish: true,

  disableVatChecker: false,
  disableIncludeVat: false,
  disableLineDelete: false,
  allowedTypes: [
    InvoiceStatus.DRAFT,
    InvoiceStatus.RECURRING,
    InvoiceStatus.DRAFT_QUOTATION,
  ],

  ghost: {},
};

export default InvoiceCreatorConfig;
