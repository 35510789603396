






























import Vue from 'vue';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import { InvoiceReceiver, Profile } from '@/models';
import { CreatorService } from '@/lib/services/meta/creator';
import InvoiceCreatorConfig from './config';
import Sender from './Sender.vue';
import Receiver from './Receiver.vue';

const DEFAULT_LOGO_POSITION = 'left';
const DEFAULT_SENDER_POSITION = 'right';

@Component({
  components: {
    Sender,
    Receiver,
  },
})
export default class InvoiceCreatorHeader extends Vue {
  @Prop()
  sender: Profile | null;
  @Prop()
  receiver: InvoiceReceiver;
  @Prop({ required: true })
  config!: InvoiceCreatorConfig;

  @Inject() creatorService!: CreatorService;

  logo: string | null = null;
  logoPosition: string = DEFAULT_LOGO_POSITION;
  senderPosition: string = DEFAULT_SENDER_POSITION;

  @Watch('sender')
  async fetchLogo(): Promise<void> {
    if (!this.sender) return;
    this.logoPosition = this.sender.logoPosition || DEFAULT_LOGO_POSITION;
    this.senderPosition = this.sender.senderPosition || DEFAULT_SENDER_POSITION;
    try {
      this.logo = this.sender.logo
        ? await this.creatorService.getLogo(this.sender.logo)
        : null;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.load.profile-logo', 0, {
          profile: this.sender.name,
        }),
      );
    }
  }

  get senderRight(): boolean {
    return this.senderPosition === 'right';
  }
}
