











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import CardLine from '@/components/CardLine.vue';

import { Invoice, InvoiceRecurringFrequence } from '@/models';

@Component({
  components: {
    CardLine,
  },
})
export default class SidebarRecurring extends Vue {
  @Prop({ required: true })
  invoice!: Invoice;

  delay: number = 1;
  interval: string = 'm';

  created(): void {
    if (this.invoice.meta.recurring) {
      [this.delay, this.interval] = this.invoice.meta.recurring.frequencePart;
    }
  }

  onChange(): void {
    if (this.invoice.meta.recurring) {
      this.invoice.meta.recurring.setFrequence(
        this.delay,
        this.interval as InvoiceRecurringFrequence,
      );
    }
  }
}
