






























































import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

import InvoiceCreatorLine from './Line.vue';
import Draggable from 'vuedraggable';
import { money as moneyFilter } from '@/lib/filters/money';

import { Invoice, InvoiceLine, InvoiceVAT, LedgerNumber } from '@/models';
import VueI18n from 'vue-i18n';
import { CreatorService } from '@/lib/services/meta/creator';
import InvoiceCreatorConfig from './config';

@Component({
  components: {
    InvoiceCreatorLine,
    Draggable,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class InvoiceCreatorLines extends Vue {
  @Prop({ required: true })
  invoice!: Invoice;
  @Prop({ required: true })
  i18n!: VueI18n;

  @Prop({ required: true })
  config!: InvoiceCreatorConfig;

  @Inject() creatorService!: CreatorService;

  vatcodes: InvoiceVAT[] = [];
  ledgernumbers: LedgerNumber[] = [];
  loaded: boolean = false;
  invalids = {};

  $refs: {
    linecontainer: Vue;
  };

  async created(): Promise<void> {
    await Promise.all([
      this.creatorService.listVatCodes().then((e) => (this.vatcodes = e)),
      this.creatorService
        .listLedgerNumbers()
        .then((e) => (this.ledgernumbers = e)),
    ]);
    this.loaded = true;
  }

  newLine(): void {
    const newLine = new InvoiceLine();
    newLine.includesVat = this.invoice.includesVat;
    this.invoice.lines.push(newLine);
    Vue.nextTick(() => {
      const lines = this.$refs.linecontainer.$children as InvoiceCreatorLine[];
      if (lines.length > 0) {
        lines[lines.length - 1].focusQuantity();
      }
    });
  }

  deleteLine(idx: number): void {
    this.invoice.lines.splice(idx, 1);
  }

  dragStart(evt: Record<string, any>): void {
    evt.item.classList.add('invoice-line-drag');
  }

  dragStop(evt: Record<string, any>): void {
    evt.item.classList.remove('invoice-line-drag');
  }

  initInvalid(type: string): void {
    if (type in this.invalids) return;

    this.invalids[type] = true;
    this.$toaster.warning(this.$tc('messages.error.lineInit.' + type));
  }
}
