






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CardLine extends Vue {
  @Prop({ required: true })
  header: string;

  @Prop() content: string;

  @Prop() comment: string;

  @Prop({ default: false })
  titleify: boolean;

  @Prop() icon: string;
}
