


















import { Vue, Component, Prop } from 'vue-property-decorator';
import { InvoiceReceiver } from '@/models';

@Component
export default class InvoiceCreatorReceiver extends Vue {
  @Prop({ required: true })
  receiver!: InvoiceReceiver;
}
