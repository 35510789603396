










































































































import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

import InvoiceCreatorHeader from './Header.vue';
import InvoiceCreatorToolbar from './Toolbar.vue';
import InvoiceCreatorDetails from './Details.vue';
import InvoiceCreatorLines from './Lines.vue';
import InvoiceCreatorSummary from './Summary.vue';
import InvoiceCreatorSidebar from './Sidebar.vue';

import Popover from '@/components/Popover.vue';
import { Invoice, InvoiceLine, Profile } from '@/models';

import { DEFAULT_I18N as i18n, newi18n, toLanguage } from '@/plugins/i18n';
import VueI18n from 'vue-i18n';

import { defaultsDeep } from 'lodash-es';
import InvoiceCreatorConfig, { DEFAULT_CONFIG } from './config';
import { CreatorService } from '@/lib/services/meta/creator';
import AutosizeTextarea from '@/components/form/AutosizeTextarea.vue';

@Component({
  components: {
    AutosizeTextarea,
    InvoiceCreatorHeader,
    InvoiceCreatorToolbar,
    InvoiceCreatorDetails,
    InvoiceCreatorLines,
    InvoiceCreatorSummary,
    InvoiceCreatorSidebar,

    Popover,
  },
  filters: {},
})
export default class Creator extends Vue {
  @Prop({ default: () => new Invoice() })
  invoice: Invoice;

  @Prop()
  initProfile: Profile | null;

  @Prop({ default: () => ({}) })
  config: InvoiceCreatorConfig;

  @Prop({ default: false })
  isModifying: boolean;

  invoiceI18n: VueI18n = i18n;
  selectedProfile: Profile | null = null;
  // Hack because vue doesnt want to listen to status updates
  isRecurring: boolean = false;

  $refs: {
    'footer-textarea': HTMLTextAreaElement;
  };

  @Inject() creatorService!: CreatorService;

  mounted(): void {
    defaultsDeep(this.config, DEFAULT_CONFIG);
    this.isRecurring = this.invoice.isRecurring;
    if (this.initProfile) {
      this.onProfileChanged(this.initProfile);
    }
  }

  async onProfileChanged(profile?: Profile): Promise<void> {
    if (!profile) return;
    // If we are already on this profile, skip setting it again
    // This also prevents initProfile being overridden by actual profile settings
    if (this.selectedProfile && profile.id === this.selectedProfile.id) return;

    this.selectedProfile = profile;
    this.invoice.meta.term = profile.expiration;
    this.invoice.sender = profile.toInvoiceSender();
    this.invoice.text.top = profile.extraText;
    this.invoice.text.bottom = profile.extraTextBottom;
    this.invoice.text.footer = profile.footer;

    try {
      if (this.invoice.isQuotation) {
        this.invoice.text.email = await this.creatorService.emailText(
          +profile.emailTextQuotationId,
        );
      } else {
        this.invoice.text.email = await this.creatorService.emailText(
          +profile.emailTextId,
        );
      }
    } catch (e) {
      this.$toaster.warning(
        this.$tc('messages.error.load.profile-emailtext', 0, {
          profile: profile.name,
        }),
      );
      this.invoice.text.email = null;
    }
  }

  async changeLanguage(lang: string): Promise<void> {
    this.invoiceI18n = await newi18n(toLanguage(lang));
  }

  isInvoiceValid(): boolean {
    return (
      this.validationMessages().length === 0 &&
      !this.invoice.lines.find((e) => !e.valid)
    );
  }

  validationMessages(): string[] {
    const messages: string[] = [];
    if (!this.invoice.receiver || !this.invoice.receiver.name) {
      messages.push('messages.error.no-customer');
    }
    // TODO: re-enable checking !this.invoice.sender.name when API is fixed!
    if (!this.selectedProfile || !this.invoice.sender) {
      messages.push('messages.error.no-profile');
    }
    if (!this.invoice.lines.length) messages.push('messages.error.no-lines');
    return messages;
  }

  async updateStatus(): Promise<void> {
    this.isRecurring = this.invoice.isRecurring;
    if (!this.selectedProfile) return;
    try {
      if (this.invoice.isQuotation) {
        if (this.invoice.text.footer === this.selectedProfile.footer) {
          this.invoice.text.footer = this.selectedProfile.footerQuotation;
        }
        this.invoice.text.email = await this.creatorService.emailText(
          +this.selectedProfile.emailTextQuotationId,
        );
      } else {
        if (this.invoice.text.footer === this.selectedProfile.footerQuotation) {
          this.invoice.text.footer = this.selectedProfile.footer;
        }
        this.invoice.text.email = await this.creatorService.emailText(
          +this.selectedProfile.emailTextId,
        );
      }
    } catch (e) {
      // This here only happens if emailText throws an error, which we dont *really* care about
      // We set it to null, so that the sendmodal tries and optionally defaults away
      this.invoice.text.email = null;
    }
    this.$refs['footer-textarea'].value = this.invoice.text.footer;
  }

  get invalidLines(): InvoiceLine[] {
    return this.invoice.lines.filter((e) => !e.valid);
  }
}
