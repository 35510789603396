

































































































import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

import SearchInput from '@/components/form/SearchInput.vue';

import { CreatorService } from '@/lib/services/meta/creator';

import { Invoice, InvoiceReceiver, InvoiceType } from '@/models/invoice';
import VueI18n from 'vue-i18n';
import InvoiceCreatorConfig from './config';
import { CustomerEditModal } from '@/components/modals';
import { Customer } from '@/models';
import Popover from '@/components/Popover.vue';

@Component({
  components: {
    Popover,
    SearchInput,
    CustomerEditModal,
  },
})
export default class InvoiceCreatorToolbar extends Vue {
  @Prop({ required: true })
  invoice!: Invoice;
  @Prop({ required: true })
  i18n!: VueI18n;
  @Prop({ required: true })
  config!: InvoiceCreatorConfig;

  customers: InvoiceReceiver[] = [];
  showModal: boolean = false;
  modalIsEditing: boolean = false;
  createCustomer: Customer = new Customer();
  editCustomer: Customer = new Customer();

  ghostNotificationDismissed: boolean = false;

  @Inject() creatorService!: CreatorService;

  async mounted(): Promise<void> {
    this.customers = await this.creatorService.listReceivers();
  }

  beforeCreateCustomer(): void {
    this.createCustomer = new Customer();
    if (this.config.ghost && this.config.ghost.customer) {
      this.createCustomer.name = this.config.ghost.customer.name;
      this.createCustomer.email = this.config.ghost.customer.email;
      if (this.config.ghost.customer.info) {
        Object.entries(this.config.ghost.customer.info).forEach(([k, v]) => {
          if (this.createCustomer.hasOwnProperty(k)) {
            this.createCustomer[k] = v;
          }
        });
      }
    }
    this.modalIsEditing = false;
    this.showModal = true;
    this.ghostNotificationDismissed = true;
  }

  onCreated(customer: Customer): void {
    const receiver = customer.toInvoiceReceiver();
    this.customers.push(receiver);
    this.invoice.receiver = receiver;
    this.showModal = false;
  }

  beforeUpdateCustomer(): void {
    this.editCustomer = Object.assign(new Customer(), this.invoice.receiver);
    this.modalIsEditing = true;
    this.showModal = true;
  }

  onUpdated(customer: Customer): void {
    const original = this.customers.find((e) => e.id === customer.id);
    Object.assign(original, customer);
    this.invoice.receiver = customer.toInvoiceReceiver();
    this.showModal = false;
  }

  changedCustomer(customer?: Customer): void {
    if (!customer) return;

    if (customer.currencyOverride) {
      this.invoice.meta.currency = customer.currencyOverride;
    }
    if (customer.expirationOverride) {
      this.invoice.meta.term = customer.expirationOverride;
    }
    if (customer.languageOverride) {
      this.invoice.meta.language = customer.languageOverride;
    }
    if (customer.extraTextOverride) {
      this.invoice.text.top = customer.extraTextOverride;
    }
    if (customer.extraTextBottomOverride) {
      this.invoice.text.bottom = customer.extraTextBottomOverride;
    }
  }

  get invoiceType(): string {
    if (!this.invoice) return 'UNKNOWN';

    if (this.invoice.isQuotation) {
      return this.i18n.tc('invoice.types.quotation').toUpperCase();
    }

    switch (this.invoice.type) {
      case InvoiceType.CREDIT:
        return this.i18n.tc('invoice.paymentTypes.credit').toUpperCase();
      case InvoiceType.AUTOMATIC_COLLECTION:
        return this.i18n.tc('invoice.paymentTypes.collection').toUpperCase();
      default:
        return this.i18n.tc('invoice.paymentTypes.standard').toUpperCase();
    }
  }

  contactOption(option?: Customer): string {
    if (!option || !option.name) {
      return '';
    }

    return option.name + (option.contact ? ', ' + option.contact : '');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  countryToLower(country: any): string {
    if (typeof country['toUpperCase'] === 'function') {
      return country.toUpperCase();
    }

    return country;
  }
}
