










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import autosize from 'autosize';

@Component
export default class AutosizeTextarea extends Vue {
  @Prop()
  value: any;

  mounted(): void {
    autosize(this.$el);
  }

  input(event: Event): void {
    const field = event.target! as HTMLTextAreaElement;
    this.$emit('input', field.value);
  }

  // TODO(joost 11-10-2021): This seems to handle raw DOM api's, not sure about the typing here and below
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get rows() {
    return this.$attrs.rows || 3;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get listeners() {
    const { input, ...listeners } = this.$listeners;
    void input;
    return listeners;
  }

  get attrs(): Record<string, string> {
    const { rows, ...attrs } = this.$attrs;
    void rows;
    return attrs;
  }
}
