
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Profile } from '@/models';

@Component
export default class InvoiceCreatorSender extends Vue {
  @Prop({ required: true })
  sender!: Profile;
}
