
















































































































































import Vue from 'vue';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import Big from 'big.js';

import { Currency } from '@/lib/currency';
import { InvoiceLedger, InvoiceLine, InvoiceVAT, Product } from '@/models';
import { money as moneyFilter } from '@/lib/filters/money';
import AutocompleteInput from '@/components/form/AutocompleteInput.vue';
import SearchInput from '@/components/form/SearchInput.vue';
import { CreatorService } from '@/lib/services/meta/creator';
import VueI18n from 'vue-i18n';
import InvoiceCreatorConfig from './config';

@Component({
  components: {
    AutocompleteInput,
    SearchInput,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class InvoiceCreatorLine extends Vue {
  @Prop({ required: true })
  line: InvoiceLine;

  @Prop({ required: true, default: () => [] })
  vatcodes: InvoiceVAT[];

  @Prop({ required: true, default: () => [] })
  ledgers: InvoiceLedger[];

  @Prop({ default: Currency.EUR })
  currency: Currency;

  @Prop({ default: false })
  includesVat: boolean;

  @Prop({ required: true })
  config!: InvoiceCreatorConfig;

  @Prop({ required: true })
  i18n!: VueI18n;

  @Inject() creatorService!: CreatorService;

  $refs: {
    quantity: HTMLInputElement;
  };

  private quantity: string = '';
  private price: string = '';
  private discount: string = '';
  private showDiscount: boolean = false;

  created(): void {
    if (this.line.quantity !== null) {
      this.quantity = this.line.quantity.toString();
    }

    if (this.line.price !== null) {
      this.price = this.line.price.toString();
    }

    this.showDiscount = !this.line.discount.eq(0);
    this.discount = this.line.discount.toString();

    if (
      this.line.vat !== null &&
      !this.vatcodes.find((v) => v.id === this.line.vat!.id)
    ) {
      this.line.vat = null;
      this.$emit('invalid', 'vat');
    }

    if (
      this.line.ledger !== null &&
      !this.ledgers.find((v) => v.id === this.line.ledger!.id)
    ) {
      this.line.ledger = null;
      this.$emit('invalid', 'ledger');
    }
  }

  onQuantity(event: Event): void {
    if (!(event && event.target)) {
      return;
    }
    const value = (event.target as HTMLInputElement).value.replace(',', '.');

    if (value === '') {
      this.line.quantity = null;
      this.quantity = '';
    } else {
      try {
        this.line.quantity = new Big(value);
        this.quantity = this.line.quantity.toString();
      } catch (e) {
        this.line.quantity = null;
        this.quantity = '';
      }
    }
  }

  onPrice(event: Event): void {
    if (!(event && event.target)) {
      return;
    }
    const value = (event.target as HTMLInputElement).value.replace(',', '.');

    if (value === '') {
      this.line.price = null;
      this.price = '';
    } else {
      try {
        this.line.price = new Big(value);
        this.price = this.line.price.toFixed(this.maxDecimals);
      } catch (e) {
        this.line.price = null;
        this.price = '';
      }
    }
  }

  onDiscount(event: Event): void {
    if (!(event && event.target)) {
      return;
    }
    const value = (event.target as HTMLInputElement).value.replace(',', '.');

    if (value === '') {
      this.line.discount = new Big(0);
      this.discount = '';
    } else {
      try {
        this.line.discount = new Big(value);
        this.discount = this.line.discount.toString();
      } catch (e) {
        this.line.discount = new Big(0);
        this.discount = '';
      }
    }
  }

  @Watch('currency')
  fixNumber(): void {
    const x =
      this.line.price === null
        ? null
        : new Big(this.line.price.toFixed(this.maxDecimals));
    this.line.price = null;
    this.line.price = x;
  }

  @Watch('includesVat')
  onIncludesVatChange(): void {
    this.line.includesVat = this.includesVat;
  }

  onProductSelect(product: Product): void {
    this.line.description = product.description;
    this.line.price = new Big(product.price);
    this.price = product.price.toFixed(this.maxDecimals);
    const vatCode = this.vatcodes.find((e) => e.id === product.vatId);
    this.line.vat = vatCode || null;
    const ledger = this.ledgers.find((e) => e.id === product.ledgerNumberId);
    this.line.ledger = ledger || null;
  }

  focusQuantity(): void {
    this.$refs.quantity.focus();
  }

  get formatter(): Intl.NumberFormat {
    return new Intl.NumberFormat(window.navigator.language, {
      style: 'currency',
      currency: this.currency,
    });
  }

  get currencyPrefix(): string {
    return this.formatter.format(0).replace(/[0,\s.-]/g, '');
  }

  get maxDecimals(): number {
    return (
      this.formatter.resolvedOptions() as Intl.ResolvedNumberFormatOptions
    ).maximumFractionDigits;
  }

  get stepSize(): number {
    return 1 / Math.pow(10, this.maxDecimals);
  }

  get vatName(): string {
    if (!this.line.vat) return '';
    return this.line.vat.name;
  }

  isDescriptiveLine(): boolean {
    return !this.line.vat && !this.line.ledger;
  }

  toggleShowDiscount(): void {
    this.line.discount = new Big(0);
    this.discount = '';
    this.showDiscount = !this.showDiscount;
  }
}
