














































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { money as moneyFilter } from '@/lib/filters/money';

import { Invoice, InvoiceVAT } from '@/models';
import VueI18n from 'vue-i18n';
import InvoiceCreatorConfig from './config';

@Component({
  filters: {
    money: moneyFilter,
  },
})
export default class InvoiceCreatorSummary extends Vue {
  @Prop({ required: true })
  invoice!: Invoice;
  @Prop({ required: true })
  i18n!: VueI18n;
  @Prop({ required: true })
  config!: InvoiceCreatorConfig;

  get nonZeroUsedVATCodes(): InvoiceVAT[] {
    return this.invoice.usedVATCodes().filter((e) => e.percentage !== 0);
  }
}
